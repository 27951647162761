<template>
  <v-container class="outer-container">
    <v-row no-gutters>
      <v-col cols="6">
        <b>{{ drill.name }}</b>
      </v-col>
      <v-col cols="12">
        <span v-for="(group) in drill.groups" :key="group" style="margin-right:10px">{{ group }}</span>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12" style="white-space: break-spaces;">

        {{ drill.notes }}
      </v-col>
    </v-row>

    <v-row no-gutters v-if="showImages">
      <v-col cols="12" sm="4" v-for="(image) in drill.images" :key="image">
        <div style="display:grid">
          <ImageDisplay :teamId="teamId" :fileName="image"></ImageDisplay>
        </div>
      </v-col>
    </v-row>
    <v-row no-gutters v-if="showImages">
      <v-col cols="12">
        <ul class="link-list">
          <li v-for="link in drill.links" :key="link">
            <a :href="link.url" target="_blank">{{ link.title }}</a>
          </li>
        </ul>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import ImageDisplay from "@/components/components/ImageDisplay.vue";

export default {
  name: 'DrillView',
  props: ['drill', 'teamId', 'showImages'],
  components: {
    ImageDisplay
  },
  data() {
    return {
      drillNotes: ""
    }
  },
  created() {
    if (this.drill.notes) {
      this.drillNotes = this.drill.notes.replaceAll("\n", "<br>")
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.link-list {
  list-style: none;
}
</style>
   