import axios from "axios";

export default {
    me: {
        get(resultHandler, errorHandler) {
            axios
                .get('/api/me')
                .then(result => {
                    resultHandler(result.data, null);
                }).catch(err => {
                    console.error("Unable to get me", err);
                    if (errorHandler) {
                        errorHandler(err);
                    }
                });
        }
    },
    drills: {
        listForTeam(teamId, resultHandler, errorHandler) {
            if(!teamId) {
                resultHandler([],null);
                return;
            }
            axios
                .get(`/api/teams/${teamId}/drills`)
                .then(result => {
                    resultHandler(result.data, null)
                }).catch(err => {
                if (errorHandler) {
                    errorHandler(err);
                }
            });
        },
        save(teamId, drill, resultHandler, errorHandler) {
            if(drill.id) {
                axios
                    .put(`/api/teams/${teamId}/drills/${drill.id}`, drill)
                    .then(result => {
                        resultHandler(result.data, null)
                    }).catch(err => {
                    if (errorHandler) {
                        errorHandler(err);
                    }
                });
            }else{
                axios
                    .post(`/api/teams/${teamId}/drills`, drill)
                    .then(result => {
                        resultHandler(result.data, null)
                    }).catch(err => {
                    if (errorHandler) {
                        errorHandler(err);
                    }
                });
            }

        },
        delete(teamId, drillId, resultHandler, errorHandler) {
            axios
                .delete(`/api/teams/${teamId}/drills/${drillId}`)
                .then(result => {
                    resultHandler(result.data, null)
                }).catch(err => {
                if (errorHandler) {
                    errorHandler(err);
                }
            });
        },
    },
    teams: {
        get(id, resultHandler, errorHandler) {
            axios
                .get(`/api/teams/${id}`)
                .then(result => {
                    resultHandler(result.data, null)
                }).catch(err => {
                    if (errorHandler) {
                        errorHandler(err);
                    }
                });
        },
        save(team, resultHandler, errorHandler) {
            if(team.id) {
                axios
                    .put(`/api/teams/${team.id}`, team)
                    .then(result => {
                        resultHandler(result.data, null)
                    }).catch(err => {
                        if (errorHandler) {
                            errorHandler(err);
                        }
                    });
            }else{
                axios
                    .post(`/api/teams`, team)
                    .then(result => {
                        resultHandler(result.data, null)
                    }).catch(err => {
                        if (errorHandler) {
                            errorHandler(err);
                        }
                    });
            }

        },
    },
    templates: {
        get(teamId, resultHandler, errorHandler) {
            axios
                .get(`/api/teams/${teamId}/templates`)
                .then(result => {
                    resultHandler(result.data, null)
                }).catch(err => {
                if (errorHandler) {
                    errorHandler(err);
                }
            });
        },
        save(teamId,template, resultHandler, errorHandler) {
            axios
                .put(`/api/teams/${teamId}/templates/${template.id}`, template)
                .then(result => {
                    resultHandler(result.data, null)
                }).catch(err => {
                if (errorHandler) {
                    errorHandler(err);
                }
            });

        },
        new(teamId,resultHandler, errorHandler) {
            axios
                .post(`/api/teams/${teamId}/templates`, null)
                .then(result => {
                    resultHandler(result.data, null)
                }).catch(err => {
                if (errorHandler) {
                    errorHandler(err);
                }
            });

        },
    },
    practicePlans :{
        get(id, resultHandler, errorHandler) {
            axios
                .get(`/api/plans/${id}`)
                .then(result => {
                    resultHandler(result.data, null);
                }).catch(err => {
                    if (errorHandler) {
                        errorHandler(err);
                    }
                });
        },
        search(searchText, resultHandler, errorHandler) {
            axios
                .post(`/api/plans/search`, {
                    'searchText' : searchText,
                })
                .then(result => {
                    resultHandler(result.data, null);
                }).catch(err => {
                if (errorHandler) {
                    errorHandler(err);
                }
            });
        },
        getRange(startDate, endDate, resultHandler, errorHandler) {

            axios
                .get(`/api/plans/find/start/${Math.round(startDate.getTime()/1000)}/end/${Math.round(endDate.getTime()/1000)}`)
                .then(result => {
                    resultHandler(result.data, null);
                }).catch(err => {
                if (errorHandler) {
                    errorHandler(err);
                }
            });
        },
        getShared(id, resultHandler, errorHandler) {
            axios
                .get(`/api/shared/${id}`)
                .then(result => {
                    resultHandler(result.data, null);
                }).catch(err => {
                if (errorHandler) {
                    errorHandler(err);
                }
            });
        },
        save(teamId, practicePlan, resultHandler, errorHandler) {
            if(practicePlan.id && practicePlan.id != "new") {
                axios
                    .put(`/api/teams/${teamId}/plans/${practicePlan.id}`, practicePlan)
                    .then(result => {
                        resultHandler(result.data, null)
                    }).catch(err => {
                        if (errorHandler) {
                            errorHandler(err);
                        }
                    });
            }else{
                axios
                    .post(`/api/teams/${teamId}/plans`, practicePlan)
                    .then(result => {
                        resultHandler(result.data, null)
                    }).catch(err => {
                        if (errorHandler) {
                            errorHandler(err);
                        }
                    });
            }

        },
        delete(teamId, practicePlanId, resultHandler, errorHandler) {
            if(practicePlanId != "new") {
                axios
                    .delete(`/api/teams/${teamId}/plans/${practicePlanId}`)
                    .then(result => {
                        resultHandler(null, null)
                    }).catch(err => {
                    if (errorHandler) {
                        errorHandler(err);
                    }
                });
            }else{
                resultHandler(null, null)
            }

        },
        listRecent(resultHandler, errorHandler) {
            axios
                .get(`/api/me/plans`)
                .then(result => {
                    resultHandler(result.data, null)
                }).catch(err => {
                    if (errorHandler) {
                        errorHandler(err);
                    }
                });
        }
    },
    files: {
        uploadImage(teamId, formData, resultHandler, errorHandler) {

            console.log(formData);
            let data = formData.get("file");
            let filename = data.name;

            axios.post(`/api/teams/${teamId}/images/${filename}`)
            .then(result => {

                formData.set("key", result.data.key);
                formData.set("X-Amz-Credential", result.data.credential);
                formData.set("X-Amz-Date", result.data.date);
                formData.set("Policy", result.data.policy);
                formData.set("X-Amz-Signature", result.data.signature);
                formData.set("X-Amz-Security-Token", result.data.securityToken);
                let imageId = result.data.id;

                window.fetch(result.data.url, {
                    method: 'POST',
                    body: formData,
                }).then(()=>{
                    resultHandler(result.data.id, null);
                });


            }).catch(err => {
                if (errorHandler) {
                    errorHandler(err);
                }
            });
        }
    }
}