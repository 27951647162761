<template>
  <v-main class="d-flex " style="min-height: 300px;">

    <v-container fluid>
      <v-toolbar v-if="!loadingPlan && !isPrint">
        <v-btn :to="{ name: 'planedit', params: { id: plan.id } }" color=""
               v-if="isSharedView === false && !isPrint">
          Edit
        </v-btn>
        <v-btn :to="{ name: 'planviewprint', params: { id: plan.id } }" color=""
               v-if=" !isPrint">
          Print
        </v-btn>
        <v-btn @click="duplicate" v-if="!isPrint">
          Duplicate
        </v-btn>
        <v-btn @click="deleteReport" v-if="!isPrint" color="error">
          Delete
        </v-btn>

      </v-toolbar>
      <v-progress-linear
          :active="loadingPlan"
          color="primary"
          height="4"
          indeterminate
      ></v-progress-linear>

      <v-row no-gutters>
        <v-col cols="12" sm="8">
          <h1>{{ plan.name }}</h1>
          <h2>
            <DateTimeDisplay :time="plan.date"></DateTimeDisplay>
          </h2>
          <h2 v-if="plan.team">{{ plan.team.name }}</h2>
          {{ plan.notes }}
        </v-col>

        <v-col cols="12" sm="4" v-if="shareCodeUrl && !isSharedView && !isPrint">

          <h3>Share Code</h3>
          <label class="text--lighten-1 text-subtitle-2">Use this url to share read only access to this practice
            plan</label>
          <br>
          {{ shareCodeUrl }}
          <v-btn variant="plain" @click="copyShareUrl()">
            <v-icon icon="mdi-content-copy"></v-icon>
          </v-btn>

          <br>
          <label class="text--lighten-1 text-subtitle-2">Use this url to share the next practice for the team
          </label>
          <br>
          {{ teamShareCodeUrl }}
          <v-btn variant="plain" @click="copyTeamShareUrl()">
            <v-icon icon="mdi-content-copy"></v-icon>
          </v-btn>
        </v-col>

      </v-row>
      <br/>
      <h3 v-if="!loadingPlan">Practice Plan</h3>
      <v-row class="time-slot-row" no-gutters v-for="(timeSlot, index) in plan.timeSlots" :key="timeSlot"
             :class="{ 'is-break': timeSlot.isBreak }">
        <v-col cols="2" md="1">
          <TimeDisplay :practiceStartTime="plan.date" :timeSlots="plan.timeSlots" :index="index"
                       :duration="timeSlot.duration">
          </TimeDisplay>
        </v-col>
        <v-col cols="10" md="11">

          <v-row no-gutters v-for="drill in timeSlot.drills" :key="drill">
            <DrillView :drill=drill :teamId="plan.team.id" :showImages="true"></DrillView>
          </v-row>
          <v-row no-gutters v-if="timeSlot.isBreak">
            <div cols="12" sm="12">
              Break
            </div>

          </v-row>

        </v-col>
      </v-row>

    </v-container>
  </v-main>
</template>

<script>
import TimeDisplay from '../components/TimeDisplay.vue'
import DateTimeDisplay from '../components/DateTimeDisplay.vue'

import api from '../../api'
import DrillView from "./DrillView.vue";

export default {
  name: 'PracticePlanView',
  components: {
    TimeDisplay,
    DateTimeDisplay,
    DrillView,
  },
  created() {
    // watch the params of the route to fetch the data again
    this.$watch(
        () => {
          this.isSharedView = this.$route.name === "sharedview";
          this.isPrint = this.$route.name === "planviewprint";
        },
        () => {
          this.isSharedView = this.$route.name === "sharedview";
          this.isPrint = this.$route.name === "planviewprint";
          this.fetchData(this.$route.params.id);
        },
        {immediate: true}
    )
  },
  methods: {
    deleteReport() {

      api.practicePlans.delete(this.plan.team.id, this.plan.id, () => {
        this.$router.push({name: 'home'})
      }, () => {
        this.errorMessage = "Unable to delete plan"
      });

    },
    duplicate() {

      let copiedPlan = JSON.parse(JSON.stringify(this.plan))
      copiedPlan.id = "new";
      copiedPlan.name = "Duplicate " + copiedPlan.name;

      api.practicePlans.save(copiedPlan.team.id, copiedPlan, (savedPlan) => {
        this.$toast.open({message: 'Drill copied', type: 'success'});
        this.$router.push({name: 'planview', params: {id: savedPlan.id}})
        this.plan = savedPlan;

      }, () => {
        this.errorMessage = "Unable to duplicate plan"
      });


    },
    copyShareUrl() {
      navigator.clipboard.writeText(this.shareCodeUrl);

      this.$toast.open({message: 'URL Copied', type: 'success'});
    },
    copyTeamShareUrl() {
      navigator.clipboard.writeText(this.teamShareCodeUrl);

      this.$toast.open({message: 'URL Copied', type: 'success'});
    },
    fetchData(id) {
      this.error = null;
      this.loadingPlan = true;

      let planHandler = api.practicePlans.get;

      if (this.isSharedView) {
        planHandler = api.practicePlans.getShared;
      }

      planHandler(id, (plan) => {
        this.isPrint = this.$route.name === "planviewprint";
        this.loadingPlan = false;

        if (plan.timeSlots) {
          plan.timeSlots.forEach((t) => {
            if (!t.drills) {
              return
            }
            t.drills.forEach((d) => {
              if (!d.links) {
                return
              }
              d.links.forEach((l) => {
                let url = l.url.trim();
                if (url.indexOf("http") != 0) {
                  url = "https://" + url;
                }

                l.url = url;
              });
            });
          });
        }


        this.plan = plan;

        if (!this.isSharedView) {
          this.shareCodeUrl = `https://${window.location.host}/share/${plan.shareCode}`
          this.teamShareCodeUrl = `https://${window.location.host}/share/team/${plan.team.id}`
        }
      }, () => {
        this.loadingPlan = false;
      })
    }
  },
  data() {
    return {
      plan: {},
      shareCodeUrl: "",
      teamShareCodeUrl: "",
      loadingPlan: false,
      isPrint: false,
    }
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.is-break {
  color: black;
  background-color: lightgray;
}

.time-slot-row {
  border-bottom: 1px solid grey;
}

</style>
  