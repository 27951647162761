<template>
  <v-main class="d-flex " style="min-height: 300px; height:95vh; overflow-y:auto">


    <v-container class="outer-container">
      First Date
      <VDatePicker title-position="left" mode="date" v-model="startDate" borderless/>
      <VDatePicker title-position="left" mode="time" v-model="startDate" :rules="timeRules" hide-time-header
                   time-accuracy=2 borderless/>

      <v-text-field type="number" label="Number of Days" v-model="days"
                    hide-details="auto"></v-text-field>

      <v-btn class='ma-1' size="32px" v-bind="props"
             @click="addTimeSlot()">
        add
      </v-btn>

      <v-table>
        <tbody>

        <tr>
          <td></td>
          <td></td>

          <td v-for="(practice) in this.practices"
              :key="practice">
            {{ practice.date }}
          </td>

        </tr>
        <tr
            v-for="(value) in this.timeSlots"
            :key="value"
        >
          <td>
            <v-text-field type="number" label="Duration" v-model="value.duration"
                          hide-details="auto"></v-text-field>
          </td>
          <td>
            <v-text-field label="Notes" v-model="value.notes"
                          hide-details="auto"></v-text-field>
          </td>
          <td v-for="(practice) in value.practices"
              :key="practice">
            <v-text-field v-model="practice.notes"
                          hide-details="auto"></v-text-field>
          </td>

        </tr>
        </tbody>
      </v-table>
    </v-container>
  </v-main>
</template>

<script>

import {ref} from "vue";

export default {
  name: 'PlanGrid',
  props: ['teamId'],
  data() {
    return {
      days: 5,
      startDate: ref(new Date()),
      timeRules: ref({
        // minutes: {interval: 15},
        minutes: [0, 15, 30, 45],
      }),
      timeSlots: [],
    }
  },
  created() {

    this.$watch(
        () => this.startDate,
        () => {

          if (!this.startDate) {
            return
          }

          // let date = this.startDate;
          //
          // if (this.timeSlots && this.timeSlots.length > 0) {
          //   this.timeSlots.forEach(timeSlot => {
          //     if (timeSlot.practices.length > 0) {
          //       for (let i = 0; i < this.days; i++) {
          //         // timeSlot.practices[i].date = date;
          //         // date.setDate(date.getDate() + 1);
          //
          //       }
          //     }
          //
          //   })
          //
          // }

        },
        // fetch the data when the view is created and the data is
        // already being observed
        {immediate: true}
    )
  },
  methods: {
    addTimeSlot() {
      let practices = []
      let date = this.startDate;
      for (let i = 0; i < this.days; i++) {

        practices.push({notes: "", date: date.setDate(date.getDate() + 1)})
      }
      this.timeSlots.push({
        notes: "",
        duration: 10,
        practices: practices,
      })

      console.log(this.timeSlots)
    }
  }
}
</script>

<style scoped>

</style>