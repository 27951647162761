<template>
  <v-main class="d-flex " style="min-height: 300px;">
    <v-container class="outer-container">
      <v-row>
        <v-col cols="12" md="6">
          <v-row>
            <v-col cols="12" sm="12">
              <v-card class="border">
                <v-card-title>
                  Upcoming Practice Plans
                </v-card-title>
                <v-card-text>
                  <v-progress-linear
                      :active="loadingRecentPlans"
                      color="primary"
                      height="4"
                      indeterminate
                  ></v-progress-linear>


                  <ul>
                    <li v-for="(plan) in plans" :key="plan.id">

                      <router-link :to="{ name: 'planview', params: { id: plan.id } }">
                        <DateTimeDisplay :time="plan.date"></DateTimeDisplay>
                        - {{ plan.team.name }} - {{ plan.name }}
                      </router-link>
                    </li>
                  </ul>
                </v-card-text>
                <v-card-actions>
                  <router-link :to="{ name: 'planedit', params: { id: 'new' } }">
                    New
                  </router-link>
                  <!--                  <router-link :to="{ name: 'plangrid'}">-->
                  <!--                    Multiple New-->
                  <!--                  </router-link>-->
                </v-card-actions>
              </v-card>

            </v-col>

            <v-col cols="12" sm="12">
              <v-card class="border">
                <v-card-title>
                  My Teams
                </v-card-title>
                <v-card-text>
                  <v-progress-linear
                      :active="loadingTeams"
                      color="primary"
                      height="4"
                      indeterminate
                  ></v-progress-linear>
                  <ul>
                    <li v-for="(team) in teams" :key="team.id">

                      <router-link :to="{ name: 'teamedit', params: { id: team.id } }">
                        {{ team.name }}
                      </router-link>
                    </li>
                  </ul>
                </v-card-text>
                <v-card-actions>

                  <router-link v-if="canAddTeam" :to="{ name: 'teamedit', params: { id: 'new' } }">
                    New Team
                  </router-link>
                </v-card-actions>
              </v-card>

            </v-col>
            <v-col cols="12">
              <v-card class="border">
                <v-card-title>
                  Search
                </v-card-title>
                <v-card-text style="background-color: white">
                  <v-text-field
                      density="compact"
                      variant="solo"
                      label="Search"
                      append-inner-icon="mdi-magnify"
                      single-line
                      hide-details
                      v-model="searchText"
                      @click:append-inner="onSearchClick"
                  ></v-text-field>


                  <v-progress-linear
                      :active="isSearching"
                      color="primary"
                      height="4"
                      indeterminate
                  ></v-progress-linear>
                  <ul>
                    <li v-for="(plan) in searchPlans" :key="plan.id">

                      <router-link :to="{ name: 'planview', params: { id: plan.id } }">
                        <DateTimeDisplay :time="plan.date"></DateTimeDisplay>
                        - {{ plan.name }}
                      </router-link>
                    </li>
                  </ul>


                </v-card-text>

              </v-card>

            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="6">
          <v-row>
            <v-col cols="12">
              <v-card class="border">
                <v-card-title>
                  Calendar
                </v-card-title>
                <v-card-text style="background-color: white">

                  <VueDatePicker v-model="selectedMonth" month-picker/>
                  <v-progress-linear
                      :active="loadingDayPlans"
                      color="primary"
                      height="4"
                      indeterminate
                  ></v-progress-linear>
                  <ul>
                    <li v-for="(plan) in events" :key="plan.id" class="calendar-list">

                      <v-row class="calendar-plan-title">
                        <v-col cols="12">
                          <router-link :to="{ name: 'planview', params: { id: plan.id } }">
                            <DateTimeDisplay :id="plan.id" :time="plan.plan.date"></DateTimeDisplay>
                            - {{ plan.plan.team.name }} - {{ plan.plan.name }}
                          </router-link>
                        </v-col>
                      </v-row>
                      <v-row v-if="plan.plan.notes" class="calendar-plan-notes">
                        <v-col cols="1"></v-col>
                        <v-col cols="11" md="11">
                          {{ plan.plan.notes }}
                        </v-col>
                      </v-row>

                    </li>
                  </ul>
                  <br/>
                  <br/>
                  <br/>
                  <br/>
                  <br/>
                  <br/><br/>
                  <br/>
                  <br/>
                  <br/>
                  <br/>
                  <br/><br/>
                  <br/>
                  <br/>
                  <br/>
                  <br/>
                  <br/><br/>
                  <br/>
                  <br/>
                  <br/>
                  <br/>
                  <br/>
                </v-card-text>

              </v-card>

            </v-col>
          </v-row>

        </v-col>
      </v-row>


    </v-container>
  </v-main>
</template>

<script>
import {ref} from 'vue';
import {currentUserStore} from "@/stores/currentUserStore";
import api from '../api'
import DateTimeDisplay from './components/DateTimeDisplay.vue'
import {VCalendar} from 'vuetify/labs/VCalendar'

const defaultColors = ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'];

export default {
  name: 'HomePage',
  components: {
    DateTimeDisplay
  },
  data() {

    return {
      selectedMonth: {
        year: new Date().getFullYear(),
        month: new Date().getMonth(),
      },
      loadingRecentPlans: true,
      loadingDayPlans: true,
      loadingTeams: true,
      canAddTeam: false,
      user: ref({}),
      plans: [],
      dayPlans: [],
      searchText: "",
      isSearching: false,
      searchPlans: [],
      calendarMonth: null,
      events: [],
    }
  },
  created() {
    this.$watch(
        () => this.selectedMonth,
        () => {
          this.loadDayPlans()
        },
        // fetch the data when the view is created and the data is
        // already being observed
        {immediate: true}
    )
  },
  mounted() {
    let self = this;
    this.user = currentUserStore().currentUser;
    this.setup();
    currentUserStore().$subscribe(() => {
      self.user = currentUserStore().currentUser;
      this.setup();
    })
  },

  methods: {
    onSearchClick() {
      this.isSearching = true;
      console.log(this.searchText)

      api.practicePlans.search(this.searchText, (plans) => {
        this.searchPlans = plans;
        this.isSearching = false;
      })
    },
    loadDayPlans() {
      this.loadingDayPlans = true;

      let startDate = new Date(this.selectedMonth.year, this.selectedMonth.month, 1)
      console.log("selected day changed", startDate)

      if (startDate[0]) {
        startDate = new Date(startDate[0])
      }

      startDate = new Date(startDate.getFullYear(), startDate.getMonth(), 1);

      let endDate = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0);

      if (!endDate) {
        return;
      }

      endDate.setDate(endDate.getDate() + 1);

      api.practicePlans.getRange(startDate, endDate, (plans) => {
        let teamNames = [];
        this.dayPlans = plans;

        this.events = [];

        //setup color map
        plans.forEach((practice) => {
          if (teamNames.indexOf(practice.team.id) === -1) {
            teamNames.push(practice.team.id);
          }
        });
        teamNames = teamNames.sort();

        plans.forEach((practice) => {
          this.events.push({
            title: practice.name,
            id: practice.id,
            plan: practice,
            color: defaultColors[teamNames.indexOf(practice.team.id) % defaultColors.length]
          })
        });

        this.loadingDayPlans = false;
      })
    },
    setup() {
      this.error = null;

      if (!this.user.id) {
        return;
      }

      this.loadingTeams = false;
      this.teams = this.user.teams;

      if (this.user.subscription === "basic" && this.teams.length < 1) {
        this.canAddTeam = true;
      } else if (this.user.subscription !== "basic") {
        this.canAddTeam = true;
      }

      api.practicePlans.listRecent((recent, err) => {
        this.loadingRecentPlans = false;


        if (err) {
          this.error = err.toString()
        } else {
          this.plans = recent
        }
      })


    },
  },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
ul {
  list-style: none;
}

.v-card-text {
  font-size: 1rem;
}

.v-card-actions {
  a {
    margin-left: 10px;
  }
}

.vc-weeks {
  display: none !important;
}

.calendar-list {
  .calendar-plan-notes {
    .v-col, .v-col-11 {
      padding: 2px;
    }

  }

  .calendar-plan-title {
    .v-col, .v-col-11 {
      padding-top: 12px;
      padding-bottom: 0px;
    }
  }

}

</style>
    